import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngWalletDevelopment = loadable(() => import("../components/WalletDevelopment/english.js"));
const ArabicWalletDevelopment = loadable(() => import("../components/WalletDevelopment/arabic.js"));
const ChineseWalletDevelopment = loadable(() => import("../components/WalletDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-14.webp",
            "name": "Top Cryptocurrency Wallet Development Company",
            "description": "Softtik is a top cryptocurrency wallet development company with over eight years of experience providing high-end development solutions to clients.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "146"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is token development",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>A token is a data or information string that is secure. Tokens are created on a blockchain that already exists. Tokens simplify transactions in developing decentralized apps and executing smart contracts on a blockchain.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How much does it cost to create a token??",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The cost of creating a token depends on your requirements and the type of the token. Reach out to <b>Softtik Technologies</b> to discuss your project idea and know the cost of developing a token.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Can you make money by creating a token?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Yes, you can make money by creating and selling tokens. Tokenization is the first stage in establishing or representing a blockchain attest. Once an asset is tokenized, it may be traded or swapped for any other asset on a global scale without any intermediaries.</p>"
                }
            }
            ]
        }
    ]
}

export class WalletDevelopment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "English"
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');
        if (region == 'ae') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/cryptocurrency-wallet-development-services/"
                        title="Cryptocurrency Wallet Development Company - Softtik"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-14.webp"
                        description="Softtik is a top cryptocurrency wallet development company with over eight years of experience providing high-end development solutions to clients."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicWalletDevelopment />
                                    : lang == "China"
                                        ? <ChineseWalletDevelopment />
                                        : <EngWalletDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default WalletDevelopment;